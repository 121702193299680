import logo from './logo.svg';
import './App.css';
import { FeatureHub, useFeature } from 'featurehub-react-sdk';
import { Component } from './Compontent';
import { useState, useEffect } from 'react';
import { getStaticAppSettings } from './staticAppSettings';

function App() {
  // call the getStaticAppSettings function and get the foo variable from the process.env object
  // don't load ui until the settings are loaded
  const [settings, setSettings] = useState<{ bar: string; foo: string; } | undefined>(undefined);
  useEffect(() => {
    getStaticAppSettings(process.env.NODE_ENV).then((settings) => {
      console.log(settings)
      setSettings(settings)
    });
  }, []);
  const testFeature = useFeature("test")
  console.log(testFeature)
  if (settings === undefined) {
    return <div>Loading.....</div>
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo"/>
        <p>
          Hello From Imagine Learning OTK V2.
        </p>
        <p>
          {settings?.foo}
        </p>
        <FeatureHub url="https://features.platform-nonprod.imaginelearning.com" apiKey="117d6c9f-1add-4c39-a0e5-4b53052476e5/ZLsY5dxKqhcqKgSCF9RwbZTgKvoGNu*133W8HNNDerS5ffUjzd1">
        <Component />
        </FeatureHub> 
      </header>
    </div>
  );
}

export default App;
